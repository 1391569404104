import { Button, Tooltip } from '@mui/material';
import React from 'react';

export default function TableSelectActions({ selectedRows = [] }) {
  return (
    <div
      className={`header-actions filter-box flex items-center justify-between my-shadow-2 rounded-lg px-4 py-1 w-full ${
        selectedRows?.length ? 'h-[45px] opacity-100 mb-4' : 'h-0 opacity-0'
      } overflow-hidden transition-all`}
    >
      <div>{selectedRows?.length} ta belgilangan</div>
      <div className='flex items-center gap-1'>
        <Tooltip placement='top' title='Topshiriqni yakunlash'>
          <Button title='check'>
            <i className='bi bi-calendar-check' />
          </Button>
        </Tooltip>
        <Tooltip placement='top' title="O'chirish">
          <Button>
            <i className='bi bi-trash3' />
          </Button>
        </Tooltip>
        <Tooltip placement='top' title='Masul shaxsni o’zgartirish'>
          <Button>
            <i className='bi bi-person-add' />
          </Button>
        </Tooltip>
        <Tooltip placement='top' title="Topshiriq vaqtini o'zgartirish">
          <Button>
            <i className='bi bi-clock' />
          </Button>
        </Tooltip>
        <Tooltip placement='top' title="Topshiriq turini o'zgartirish">
          <Button>
            <i className='bi bi-circle-square' />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
