import { Box, CircularProgress, Tab, Tabs } from '@mui/material';
import BackButton from 'components/ui/BackButton';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useFormSubmit from 'hooks/useFormSubmit';
import useTopPanel from 'hooks/useTopPanel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import CRMCardLeadHistory from './CRMCardLeadHistory';
import moment from 'moment';
import { InfoSection, SettingsSection, StatisticsSection } from './edit-items';

const validationSchema = yup.object({
  name: yup.string().min(3).required(),
  crm_block_id: yup.string().nullable(),
  crm_block_item_id: yup.string(),
  user_id: yup.string().nullable(),
  budget: yup.number().nullable(),
  operator_id: yup.string().nullable(),
  object_id: yup.string().nullable(),
  source_id: yup.string().nullable(),
  reject_type_id: yup.string().nullable(),
  contacts: yup
    .array()
    .of(
      yup.object({
        id: yup.string().optional(),
        name: yup.string().min(1).nullable(),
        region_id: yup.number().nullable(),
        phones: yup
          .array()
          .of(
            yup.object({
              id: yup.string().optional(),
              phone: yup.string().length(13).nullable(),
            })
          )
          .min(1),
      })
    )
    .min(1),
});

const CRMCardAddEdit = () => {
  const { setComponent } = useTopPanel();
  const { id } = useParams();
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { submit, isSubmitting } = useFormSubmit();
  const [hasError, setHasError] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  const [leadHistory, setLeadHistory] = useState({});
  const [leadTasks, setLeadTasks] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      crm_block_id: '',
      crm_block_item_id: '',
      user_id: '',
      budget: '',
      operator_id: '',
      object_id: '',
      source_id: '',
      reject_type_id: '',
      contacts: [
        {
          name: '',
          region_id: '',
          phones: [
            {
              phone: '',
            },
          ],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (id) {
        submit(
          { type: 'put', contentType: 'simple' },
          values,
          `/crm/crm-lead/${id}`,
          values.name,
          null,
          true,
          refetch
        );
      } else
        submit(
          { type: 'post', contentType: 'simple' },
          values,
          '/crm/crm-lead',
          values.name,
          null,
          true
        );
    },
  });

  const { isLoading, isFetching, refetch, data } = useQuery({
    queryKey: ['crmLeadSingle', id],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/crm-lead/edit/${id}`);
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data?.lead) {
        formik.setValues({
          name: data?.lead?.name || '',
          crm_block_id: data?.lead?.crm_block_item?.crm_block_id || '',
          crm_block_item_id: data?.lead?.crm_block_item_id || '',
          user_id: data?.lead?.user_id || '',
          budget: data?.lead?.budget || '',
          operator_id: data?.lead?.operator_id || '',
          object_id: data?.lead?.object_id || '',
          source_id: data?.lead?.source_id || '',
          reject_type_id: data?.lead?.reject_type_id || '',
          contacts:
            data?.lead?.contacts && data?.lead?.contacts.length > 0
              ? data?.lead?.contacts
              : [
                  {
                    name: '',
                    region_id: '',
                    phones: [
                      {
                        phone: '',
                      },
                    ],
                  },
                ],
        });
      }
      if (data && data?.history) {
        setLeadHistory(sortHistoryData(data.history));
        if (data && data?.lead && data?.lead?.tasks) {
          setLeadTasks(sortHistoryData({ ...data?.lead?.tasks }));
        }
      }
    },
    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleUpdateLeadHistory = async () => {
    const response = await axiosPrivate.get(`/crm/crm-lead/edit/${id}`);
    if (response?.data?.data && response?.data?.data?.history) {
      setLeadHistory(sortHistoryData({ ...response?.data?.data?.history }));
      if (
        response?.data?.data &&
        response?.data?.data?.lead &&
        response?.data?.data?.lead?.tasks
      ) {
        setLeadTasks(sortHistoryData({ ...response?.data?.data?.lead?.tasks }));
      }
    }
  };

  const sortHistoryData = (data) => {
    let uniqDay = Array.from(
      new Set(
        Object.keys(data).map((item) =>
          moment(data[item]?.created_at).format('DD_MM_YYYY')
        )
      )
    );
    uniqDay = uniqDay.sort((a, b) =>
      moment(b, 'DD_MM_YYYY').diff(moment(a, 'DD_MM_YYYY'))
    );
    let sortedData = Object.keys(data)
      .map((item) => data[item])
      .sort((a, b) => moment(a?.created_at).diff(b?.created_at));
    let formedData = {};
    for (let i = 0; i < uniqDay.length; i++) {
      formedData[uniqDay[i]] = sortedData.filter(
        (sD) => moment(sD?.created_at).format('DD_MM_YYYY') == uniqDay[i]
      );
    }
    return formedData;
  };

  const handleAddPhone = (contactInd) => {
    formik.setFieldValue(
      `contacts.${contactInd}.phones`,
      [
        ...JSON.parse(
          JSON.stringify(formik.values.contacts[contactInd].phones)
        ),
        {
          phone: '',
        },
      ],
      true
    );
  };

  const handleAddContact = () => {
    formik.setFieldValue(
      'contacts',
      [
        ...JSON.parse(JSON.stringify(formik.values.contacts)),
        {
          name: '',
          region_id: '',
          phones: [
            {
              phone: '',
            },
          ],
        },
      ],
      true
    );
  };

  const handleRemoveContact = (contactInd) => {
    let newContacts = JSON.parse(JSON.stringify(formik.values.contacts)).filter(
      (item, index) => index != contactInd
    );
    formik.setFieldValue('contacts', [...newContacts], true);
  };
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setComponent(
      <div className='crm-card-add-edit-top-wrapper'>
        <BackButton />
        <div className='ml-4 mb-6'>
          <FormTextField
            delay={0}
            duration={0}
            label='Nomi'
            fieldName='name'
            formik={formik}
            variant='standard'
          />
        </div>
      </div>
    );
  }, [formik.values.name, formik.errors.name, formik.touched.name]);

  return (
    <div className='component-add-edit-wrapper crm-card-add-edit-wrapper'>
      <div className='component-add-edit-body crm-card-add-edit-body'>
        {isLoading || isFetching ? (
          <div className='circular-progress-box py-5'>
            <CircularProgress size={35} />
          </div>
        ) : (
          <div className='crm-card-add-edit-body-wrapper'>
            <div style={{ width: '30%' }}>
              <Box
                sx={{ borderBottom: 1, borderColor: 'divider', fontSize: 12 }}
              >
                <Tabs value={tabIndex} onChange={handleChangeTab}>
                  <Tab label="Ma'lumot" value={1} />
                  <Tab label='Statistiklar' value={2} />
                  <Tab label='Sozlash' value={3} />
                </Tabs>
              </Box>

              {tabIndex == 1 && (
                <InfoSection
                  formik={formik}
                  handleAddPhone={handleAddPhone}
                  handleRemoveContact={handleRemoveContact}
                  handleAddContact={handleAddContact}
                  isSubmitting={isSubmitting}
                />
              )}
              {tabIndex == 2 && (
                <StatisticsSection createdAt={data?.lead?.created_at} />
              )}
              {tabIndex == 3 && <SettingsSection formik={formik} />}
            </div>
            <div className='lead-history-body-wrapper'>
              <CRMCardLeadHistory
                history={leadHistory}
                tasks={leadTasks}
                refetchFn={handleUpdateLeadHistory}
                isUserNew={!id}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CRMCardAddEdit;
