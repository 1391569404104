import { memo, useEffect, useState } from 'react';
import { Grid, Switch } from '@mui/material';
import { t } from 'i18next';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';

function Card({ id, label, description, checked }) {
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
    return () => {};
  }, [checked]);

  const onChange = async ({ target }) => {
    setIsChecked(target?.checked);
    const status = target?.checked ? 1 : 0;
    try {
      const response = await axiosPrivate.post(
        `/admin/sms/templateupdatestatus/${id}`,
        JSON.stringify({ status }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data && response.data.status) {
        sendNotification({
          msg: t(
            `settings.sms.alerts.${
              target.checked ? 'changeToOn' : 'changeToOf'
            }`,
            {
              value: label,
            }
          ),
          variant: 'success',
        });
      }
    } catch (error) {
      setIsChecked(isChecked);
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };

  return (
    <Grid item={true} lg={4} sm={6} xs={12}>
      <div className='p-3 my-shadow-1 rounded-lg h-full'>
        <div className='flex justify-between items-center'>
          <span className='text-md text-gray-800 whitespace-pre-wrap break-words'>
            {label || 'card label'}
          </span>
          <Switch checked={isChecked} onChange={onChange} />
        </div>
        <span className='text-sm text-gray-400'>
          {description || 'card description'}
        </span>
      </div>
    </Grid>
  );
}

export default memo(Card);
