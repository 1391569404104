/* eslint-disable eqeqeq */
import React, { memo, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useQuery } from 'react-query';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import TemplateCard from './teplate-card';

function Template() {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: '/admin/sms/templates',
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/sms/templates');
      return response?.data?.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  return (
    <div className='setting-sms-wrapper'>
      {isLoading || isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        <Grid container spacing={2} columns={{ xs: 1 }}>
          {data?.map((template) => (
            <TemplateCard key={template?.id} template={template} />
          ))}
        </Grid>
      )}
    </div>
  );
}

export default memo(Template);
