import { TableCell, TableHead, TableRow } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TableHeadWithSorting = (props) => {
  const { t } = useTranslation();

  const { headCells, onSort, sorting } = props;

  const [sortHeader, setSortHeader] = useState({ code: null, type: 'asc' });
  const { code, type } = sortHeader;
  const onClick = (changing_code) => {
    const sorting = {
      code: changing_code,
      type: changing_code != code || type == 'desc' ? 'asc' : 'desc',
    };
    setSortHeader(sorting);
    onSort(sorting.code, sorting.type);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, headCellIndex) => (
          <Fragment key={'head-cell-' + headCell.code}>
            <TableCell
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              <span
                className={`${
                  sorting?.[headCell?.code] ? 'cursor-pointer' : ''
                } select-none`}
                onClick={() =>
                  sorting?.[headCell?.code] && onClick(headCell.code)
                }
              >
                {headCell.label}

                {code == headCell.code && sorting?.[headCell?.code] ? (
                  <i
                    className={`bi bi-arrow-${
                      type == 'asc' ? 'down' : 'up'
                    }-short`}
                  />
                ) : (
                  ''
                )}
              </span>
            </TableCell>
          </Fragment>
        ))}
        <TableCell padding='normal' align='right'>
          {t('common.table.actions')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeadWithSorting;
