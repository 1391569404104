/** @format */
import { memo, useState } from 'react';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

const LightBoxComponent = ({
  captions,
  titles,
  images,
  photoIndex,
  setIsOpen,
}) => {
  const [imgId, setImgId] = useState(photoIndex);

  return (
    <Lightbox
      style={{ zIndex: 1040 }}
      imageCaption={captions[imgId % images?.length]}
      imageTitle={titles[imgId % images?.length]}
      loader={
        <div className='grid place-content-center h-full'>
          <img src={images[imgId % images?.length]} alt='' />
        </div>
      }
      mainSrc={images[imgId % images?.length]}
      nextSrc={images[(imgId + 1) % images?.length]}
      prevSrc={images[(imgId + images?.length - 1) % images?.length]}
      mainSrcThumbnail={images[imgId % images?.length]}
      nextSrcThumbnail={images[(imgId + 1) % images?.length]}
      prevSrcThumbnail={images[(imgId + images?.length - 1) % images?.length]}
      onCloseRequest={() => setIsOpen(false)}
      onMovePrevRequest={() => {
        setImgId((imgId + images?.length - 1) % images?.length);
      }}
      onMoveNextRequest={() => {
        setImgId((imgId + 1) % images?.length);
      }}
    />
  );
};

export default memo(LightBoxComponent);
