/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';

import useTopPanel from 'hooks/useTopPanel';

import System from './tabs/System';
import Template from './tabs/Template';
import Sending from './tabs/Sending';

function SMSSettings() {
  const [tabIndex, setTabIndex] = useState(1);

  const { t, i18n } = useTranslation();
  const { setComponent } = useTopPanel();

  const handleChangeTab = (event, newValue) => {
    setTabIndex(() => newValue);
  };
  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.sms.title')}</div>
    );
  }, [i18n.language]);

  return (
    <div>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs value={tabIndex} onChange={handleChangeTab} type='callsHistory'>
            <Tab label={t('settings.sms.tabs.sending')} value={1} />
            <Tab label={t('settings.sms.tabs.template')} value={2} />
            <Tab label={t('settings.sms.tabs.system')} value={3} />
          </Tabs>
        </Box>

        <div className='w-full mt-5'>
          {tabIndex == 1 && <Sending />}
          {tabIndex == 2 && <Template />}
          {tabIndex == 3 && <System />}
        </div>
      </div>
    </div>
  );
}

export default memo(SMSSettings);
