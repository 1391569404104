import { Button, Tab, Tabs } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useTopPanel from 'hooks/useTopPanel';
import TaskFilterDrawer from 'components/dashboard/TaskFilterDrawer';
import Columns from './items/Columns';
import Table from './items/Table';
import Calendar from './items/Calendar';

const Tasks = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('sidebar.admin.tasks')}</div>
    );
  }, [i18n.language]);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 py-0 w-full'>
            <div>
              <Tabs value={tabIndex} onChange={handleChangeTab}>
                <Tab
                  label='Ustun'
                  icon={<i className='bi bi-layout-three-columns' />}
                  sx={{ minHeight: 50 }}
                  iconPosition='end'
                  value={1}
                />
                <Tab
                  label='Jadval'
                  icon={<i className='bi bi-table' />}
                  sx={{ minHeight: 50 }}
                  iconPosition='end'
                  value={2}
                />
                <Tab
                  label='Taqvim'
                  icon={<i className='bi bi-calendar-day' />}
                  sx={{ minHeight: 50 }}
                  iconPosition='end'
                  value={3}
                />
              </Tabs>
            </div>
            <div className='w-full flex justify-end items-center'>
              <div className='mr-3'>
                <Button
                  variant='filterOutlined'
                  color='primary'
                  startIcon={<i className='bi bi-filter' />}
                  onClick={() => {
                    setExpanded(true);
                  }}
                >
                  {t('common.button.filter')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tabIndex === 1 && <Columns />}
      {tabIndex === 2 && <Table />}
      {tabIndex === 3 && <Calendar />}
      <TaskFilterDrawer open={expanded} setOpen={setExpanded} />
    </div>
  );
};
export default memo(Tasks);
