import React, { useState } from 'react';
import { Collapse, Grid, IconButton } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';

import CLIENT_TYPE from 'shared/clientTypeList';
import PhoneFormat from 'components/ui/text-formats/PhoneFormat';

export default function ContractViewClientInfo({ contractQuery }) {
  const { data } = contractQuery;
  const [openCollapse, setOpenCollapse] = useState(false);

  return (
    <div className='client-data my-shadow-2 rounded-lg p-6 pb-3 mt-3 w-full'>
      {data?.custom?.client_type == CLIENT_TYPE.PHYSICAL.code ? (
        <Grid
          container
          spacing={{ xs: 2, sm: 3, mdm: 3, lg: 3 }}
          rowSpacing={1}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <div className='home-data-item flex gap-1'>
              <div className='data-item-title text-[13px] font-medium text-base-color'>
                {t('contract.view.clientPhysical.custom')}:
              </div>
              <span className='data-item-value text-[13px] font-medium underline'>
                {data?.custom?.surname} {data?.custom?.name}{' '}
                {data?.custom?.middlename}
              </span>
            </div>
          </Grid>

          <Grid item={true} lg={4} md={4} sm={6} xs={12}>
            <div className='home-data-item flex flex-col'>
              <span className='data-item-title text-[13px] font-medium text-base-color'>
                {t('contract.view.clientPhysical.phone')}:
              </span>
              <span className='data-item-value text-[13px] flex flex-col'>
                <PhoneFormat value={data?.custom?.phone} />
                <PhoneFormat value={data?.custom?.phone2} />
              </span>
            </div>
          </Grid>

          <Grid item={true} lg={8} md={8} sm={6} xs={12}>
            <div className='home-data-item flex flex-col'>
              <span className='data-item-title text-[13px] font-medium text-base-color'>
                {t('contract.view.clientPhysical.home')}:
              </span>
              <span className='data-item-value text-[13px]'>
                {data?.custom?.detail?.home}
              </span>
            </div>
          </Grid>

          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Collapse in={openCollapse}>
              <div className='home-data-item flex justify-between items-center p-1 rounded-md bg-gray-100'>
                <span className='data-item-title text-[12px] font-medium text-base-color'>
                  {t('contract.view.clientPhysical.birthday')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.birthday
                    ? moment(data?.custom?.detail?.birthday).format(
                        'DD/MM/YYYY'
                      )
                    : ''}
                </span>
              </div>

              <div className='home-data-item flex justify-between items-center p-1 rounded-md'>
                <span className='data-item-title text-[12px] font-medium text-base-color'>
                  {t('contract.view.clientPhysical.passportSeries')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.passport_series}
                </span>
              </div>

              <div className='home-data-item flex justify-between items-center p-1 rounded-md bg-gray-100'>
                <span className='data-item-title text-[12px] font-medium text-base-color'>
                  {t('contract.view.clientPhysical.issue')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.issue
                    ? moment(data?.custom?.detail?.issue).format('DD/MM/YYYY')
                    : ''}
                </span>
              </div>

              <div className='home-data-item flex justify-between items-center p-1 rounded-md'>
                <span className='data-item-title text-[12px] font-medium text-base-color'>
                  {t('contract.view.clientPhysical.authority')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.authority}
                </span>
              </div>

              <div className='home-data-item flex justify-between items-center p-1 rounded-md bg-gray-100'>
                <span className='data-item-title text-[12px] font-medium text-base-color'>
                  {t('contract.view.clientPhysical.workPlace')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.work_place}
                </span>
              </div>
            </Collapse>
          </Grid>
        </Grid>
      ) : data?.custom?.client_type == CLIENT_TYPE.LEGAL.code ? (
        <Grid
          container
          spacing={{ xs: 2, sm: 3, mdm: 3, lg: 3 }}
          rowSpacing={1}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <div className='home-data-item flex'>
              <span className='data-item-title text-[13px] font-medium text-base-color'>
                {t('contract.view.clientLegal.name')}:
              </span>
              <span className='data-item-value text-[13px]'>
                {data?.custom?.name}
              </span>
            </div>
          </Grid>

          <Grid item={true} lg={4} md={4} sm={6} xs={12}>
            <div className='home-data-item flex flex-col'>
              <span className='data-item-title text-[13px] font-medium text-base-color'>
                {t('contract.view.clientLegal.phone')}:
              </span>
              <span className='data-item-value text-[13px]'>
                <PhoneFormat value={data?.custom?.phone} />
                <PhoneFormat value={data?.custom?.phone2} />
              </span>
            </div>
          </Grid>

          <Grid item={true} lg={8} md={8} sm={6} xs={12}>
            <div className='home-data-item flex flex-col'>
              <span className='data-item-title text-[13px] font-medium text-base-color'>
                {t('contract.view.clientLegal.address')}:
              </span>
              <span className='data-item-value text-[13px]'>
                {data?.custom?.detail?.home}
              </span>
            </div>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Collapse in={openCollapse}>
              <div className='home-data-item flex justify-between items-center p-1 bg-gray-100'>
                <span className='data-item-title text-[13px] font-medium text-base-color'>
                  {t('contract.view.clientLegal.accountNumber')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.account_number}
                </span>
              </div>

              <div className='home-data-item flex justify-between items-center p-1'>
                <span className='data-item-title text-[13px] font-medium text-base-color'>
                  {t('contract.view.clientLegal.tin')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.inn}
                </span>
              </div>

              <div className='home-data-item flex justify-between items-center p-1 bg-gray-100'>
                <span className='data-item-title text-[13px] font-medium text-base-color'>
                  {t('contract.view.clientLegal.mfo')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.mfo}
                </span>
              </div>

              <div className='home-data-item flex justify-between items-center p-1'>
                <span className='data-item-title text-[13px] font-medium text-base-color'>
                  {t('contract.view.clientLegal.oked')}:
                </span>
                <span className='data-item-value text-[13px]'>
                  {data?.custom?.detail?.oked}
                </span>
              </div>
            </Collapse>
          </Grid>
        </Grid>
      ) : (
        <div>
          <span className='no-data-found-wrapper'>
            <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
            {t('common.global.noDataFound')}
          </span>
        </div>
      )}
      <div className='flex justify-center mt-2'>
        {data?.custom?.client_type == CLIENT_TYPE.PHYSICAL.code ||
        data?.custom?.client_type == CLIENT_TYPE.LEGAL.code ? (
          <IconButton
            variant='outlined'
            color='secondary'
            className='!bg-green-200'
            size='small'
            onClick={() => setOpenCollapse((prev) => !prev)}
          >
            <i
              className={`bi bi-chevron-${
                openCollapse ? 'up' : 'down'
              } text-[13px] leading-3`}
            />
          </IconButton>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
