/* eslint-disable eqeqeq */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import HOME_TYPE from 'shared/homeTypeList';
import useCurrency from 'hooks/useCurrency';
import REPAIR_TYPE from 'shared/repairTypeList';
import usePermission from 'hooks/usePermission';
import CURRENCY_TYPE from 'shared/currencyTypeList';
import { fromTextToBase64parser } from 'utils/textParser';
import { formatMultiplies } from 'utils/formatOddDecimal';
import OrderAddModal from 'pages/admin/order/OrderAddModal';
import ShaxmatkaHomeDetailToPrint from './ShaxmatkaHomeDetailToPrint';
import CalculationByHomeData from './calculation/CalculationByHomeData';

const ShaxmatkaMoreHomeDetailsModal = ({
  open,
  setOpen,
  homeData = {},
  object = {},
  setRefetch = () => {},
}) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [
    fPrice,
    // setFPrice
  ] = useState(
    homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
      ? homeData?.repaired && homeData?.square
        ? formatMultiplies([homeData.repaired, homeData.square], 2)
        : 0
      : homeData?.norepaired && homeData?.square
      ? formatMultiplies([homeData.norepaired, homeData.square], 2)
      : 0
  );
  const { currencyData } = useCurrency();
  const [homeCalculations, setHomeCalculations] = useState([
    {
      isvalute: CURRENCY_TYPE.SUM.code,
      fullPrice: 0,
      sellPrice: 0,
      startPrice: 0,
      startPricePercentage: 0,
      month: 0,
      priceForMonth: 0,
      priceForSquare: 0,
      discount: 0,
      discountPercentage: 0,
      itemKey: 0,
    },
  ]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Median_${object?.name}_${homeData?.blocks?.name}_${homeData?.number} uy`,
    pageStyle: '@page { size: auto, margin: 0mm; }',
  });

  const handleAddNewCalculation = () => {
    const itemKey = new Date().toString();
    homeCalculations.push({
      isvalute: CURRENCY_TYPE.SUM.code,
      fullPrice: 0,
      sellPrice: 0,
      startPrice: 0,
      startPricePercentage: 0,
      month: 0,
      priceForMonth: 0,
      priceForSquare: 0,
      discount: 0,
      discountPercentage: 0,
      itemKey,
    });
    setHomeCalculations(JSON.parse(JSON.stringify(homeCalculations)));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setHomeCalculationByIndex = (calculationIndex, value) => {
    homeCalculations[calculationIndex] = {
      ...homeCalculations[calculationIndex],
      ...value,
    };
    setHomeCalculations(JSON.parse(JSON.stringify(homeCalculations)));
  };

  const handleRemoveCalculationByIndex = (calculationIndex) => {
    homeCalculations.splice(calculationIndex, 1);
    setHomeCalculations(JSON.parse(JSON.stringify(homeCalculations)));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='shaxmatka-more-home-details-dialog-title'>
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {homeData && Object.keys(homeData).length > 0 && (
          <div className='shaxmatka-more-home-details-wrapper'>
            {homeCalculations.map((item, index) => (
              <CalculationByHomeData
                homeData={homeData}
                object={object}
                order={index}
                key={`calculation-${item?.itemKey || '0'}`}
                setData={setHomeCalculationByIndex}
                removeFn={handleRemoveCalculationByIndex}
              />
            ))}
            <div className='text-center mt-4'>
              <Button
                variant='outlined'
                color='success'
                className='h-9'
                onClick={() => handleAddNewCalculation()}
              >
                <i className='bi bi-calculator text-lg mr-1' />
                {/* Yangi hisob-kitob qo'shish */}
                {t('order.view.createCalculation')}
              </Button>
            </div>
            <div className='action-buttons-wrapper'>
              <div>
                <div className='hidden'>
                  <ShaxmatkaHomeDetailToPrint
                    ref={componentRef}
                    homeData={{
                      repaired: homeData?.repaired,
                      norepaired: homeData?.norepaired,
                      rooms: homeData?.rooms,
                      islive: homeData?.islive,
                      stage: homeData?.stage,
                      number: homeData?.number,
                      status: homeData?.status,
                      plan: homeData?.plan,
                      square: homeData?.square,
                      fullPriceSum:
                        homeData?.isvalute == CURRENCY_TYPE.SUM.code
                          ? fPrice
                          : fPrice * currencyData.sum,
                      fullPriceValute:
                        homeData?.isvalute == CURRENCY_TYPE.SUM.code
                          ? fPrice / currencyData.sum
                          : fPrice,
                      calculations: homeCalculations,
                    }}
                    blockName={homeData?.blocks?.name}
                    objectData={object}
                  />
                </div>
                <Button
                  variant='outlined'
                  color='success'
                  onClick={() => handlePrint()}
                  className='h-9'
                  size='small'
                >
                  <i className='bi bi-printer text-lg mr-1' />
                  {/* Chop etish */}
                  {t('common.button.print')}
                </Button>
              </div>
              <div className='flex items-center'>
                {homeData?.status &&
                  homeData.status == HOME_TYPE.ACTIVE.code &&
                  hasPermission('ORDER_HOMES') && (
                    <Button
                      variant='contained'
                      color='warning'
                      className='h-9'
                      size='small'
                      onClick={() => setOpenOrderModal(true)}
                    >
                      {/* Buyurtma berish */}
                      {t('order.view.createOrder')}
                    </Button>
                  )}

                {homeData?.status &&
                  homeData.status == HOME_TYPE.ACTIVE.code && (
                    <Button
                      variant='contained'
                      color='success'
                      className='h-9 !ml-2 !p-0'
                      size='small'
                    >
                      <Link
                        to={`/admin/contract/add-from-shaxmatka?homeData=${fromTextToBase64parser(
                          JSON.stringify(homeData)
                        )}`}
                        className='no-underline py-1.5 px-3.5 whitespace-nowrap'
                        target='_blank'
                      >
                        {/* Shartnoma tuzish */}
                        {t('order.view.createContract')}
                      </Link>
                    </Button>
                  )}
              </div>
            </div>
          </div>
        )}
        {openOrderModal && hasPermission('ORDER_HOMES') && (
          <OrderAddModal
            open={openOrderModal}
            setOpen={setOpenOrderModal}
            data={homeData}
            setRefetch={setRefetch}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(ShaxmatkaMoreHomeDetailsModal);
