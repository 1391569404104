import { ButtonBase } from '@mui/material';
import { motion } from 'framer-motion';
import React, { Fragment } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { fadeUp } from 'utils/motion';

const NavBarLinkItem = ({
  linkData = {
    path: '',
    title: '',
  },
  iconName = null,
  subIconName = null,
  delay = 0,
  customTextComponent = null,
  customChild = null,
}) => {
  const matchLink = useMatch({
    path: `/${linkData.path}`,
    end: `/${linkData.path}`.length == 1,
  });

  return (
    <motion.li
      variants={fadeUp(30, 'spring', delay, 1)}
      initial='hidden'
      animate='show'
      viewport={{ once: true, amount: 0.25 }}
      tabIndex={-1}
    >
      {customChild ? (
        <div>{customChild}</div>
      ) : (
        <Fragment>
          <ButtonBase
            className={`w-full navigation-button${
              matchLink ? ' active-link' : ''
            }`}
            tabIndex={-1}
          >
            <Link
              to={'/' + linkData.path}
              className='navigation-link-bottom no-underline'
            >
              {iconName && <i className={iconName + ' link-icon'} />}
              {subIconName && <i className={subIconName + ' link-sub-icon'} />}
              {customTextComponent ? (
                customTextComponent
              ) : (
                <div className='link-title grow text-left'>
                  {linkData.title}
                </div>
              )}
            </Link>
            <Link
              to={'/' + linkData.path}
              className='navigation-link-top no-underline'
              tabIndex='-1'
            >
              {iconName && <i className={iconName + ' link-icon'} />}
              {subIconName && <i className={subIconName + ' link-sub-icon'} />}
              {customTextComponent ? (
                customTextComponent
              ) : (
                <div className='link-title grow text-left'>
                  {linkData.title}
                </div>
              )}
            </Link>
          </ButtonBase>
        </Fragment>
      )}
    </motion.li>
  );
};

export default NavBarLinkItem;
