import { Button } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import { useState } from "react"

const CRMOperatorSync = () => {
	const [isLoading, setIsLoading] = useState(false)
	const axiosPrivate = useAxiosPrivate()
	const sendNotification = useNotification()

	const handleOperatorSync = async () => {
		if (!isLoading) {
			try {
				setIsLoading(true)
				const response = await axiosPrivate.post(
					"/crm/operator/sync",
					{},
					{
						headers: { "Content-Type": "application/json" }
					}
				)
				setIsLoading(false)
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsLoading(false)
			}
		}
	}

	return (
		<div
			className="hover:bg-gray-100 cursor-pointer rounded-lg p-1"
			onClick={() => {
				handleOperatorSync()
			}}
		>
			<Button
				variant="action"
				color="info"
				size="small"
				disable={`${isLoading}`}
			>
				<i
					className={`bi bi-arrow-repeat${isLoading ? " animate-spin" : ""}`}
				/>
			</Button>
			<span className="ml-1 text-sm">Operatorlar sinxronizatsiyasi</span>
		</div>
	)
}
export default CRMOperatorSync
