import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const radios = [
  {
    code: 'tilltoday',
    label: 'Shu kungacha',
  },
  {
    code: 'today',
    label: 'Bugun',
  },
  {
    code: 'yesterday',
    label: 'Kecha',
  },
  {
    code: 'week',
    label: 'Joriy hafta',
  },
  {
    code: 'month',
    label: 'Joriy oy',
  },
  {
    code: 'custom',
    label: 'Maxsus',
  },
];

const HideRepeatedCallFilter = ({ disabled = false, queries, label }) => {
  const fromQuery = queries?.[0] || 'from';
  const tillQuery = queries?.[1] || 'till';
  const { t } = useTranslation();
  const [repairTypeValue, setRepairTypeValue] = useState('tilltoday');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRepairTypeChange = (value) => {
    
    searchParams.set("hide_repeated_call",value);
    
    setSearchParams(searchParams);
    setRepairTypeValue(value);
  };

  return (
    <>
      <div className='mt-4 w-full flex items-center' style={{borderRadius:10}}>
        <Checkbox size='small' onChange={(e) => handleRepairTypeChange(e.target.checked)}   />
        <Typography variant='caption' fontSize={9}>Takroriy qo'ng'iroqlarni yashirish</Typography>
      </div>
    </>
  );
};
export default HideRepeatedCallFilter;
