import { Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useSearch from 'hooks/useSearch';
import useSetQuery from 'hooks/useSetQuery';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import CRMBlockAddModal from '../../../CRMBlockAddModal';
import { useLocation } from 'react-router-dom';

const CRMBlocksMenu = ({ addAllowed = false }) => {
  const query = useSearch();
  const { pathname } = useLocation();
  const setQuery = useSetQuery();
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  let anchorEl = document.getElementById('crm-block-btn');

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: 'crmBlocksList',
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/block');
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (data) => {
      if (data && data.length > 0) {
        if (!query.get('crm_block') && pathname === '/admin/crm') {
          setQuery('crm_block', data?.[0]?.id);
          setQuery('crm_block_name', data?.[0]?.name);
        }
      }
    },
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    if (!query.get('crm_block') && pathname === '/admin/crm') {
      setQuery('crm_block', data?.[0]?.id);
      setQuery('crm_block_name', data?.[0]?.name);
    }
    return () => {};
  }, [data, query.get('crm_block'), pathname]);

  const handleChangeCRMBlock = (item) => {
    setQuery('crm_block', item?.id);
    setQuery('crm_block_name', item?.name);
    menuToggle();
  };
  const menuToggle = () => {
    setOpen((prev) => !prev);
  };

  return isLoading && isFetching ? (
    <CircularProgress size={25} color='inherit' />
  ) : (
    query.get('crm_block') && (
      <div className='crm-blocks-wrapper'>
        <Button
          id='crm-block-btn'
          className='crm-block-btn'
          onClick={menuToggle}
          variant='select-menu'
        >
          {query.get('crm_block_name') || ''}
          <i
            className={`bi bi-chevron-down btn-icon ${
              open ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </Button>
        <Menu
          open={open}
          anchorEl={anchorEl}
          disableScrollLock={true}
          onClose={menuToggle}
          className='crm-blocks-menu'
        >
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <MenuItem
                key={`crm-block-${index + 1}`}
                onClick={() => handleChangeCRMBlock(item)}
                className={
                  query.get('crm_block') == item?.id ? 'is-active' : ''
                }
              >
                {item.name}
              </MenuItem>
            ))
          ) : (
            <div>
              <span className='no-data-found-wrapper select-box px-4'>
                <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                {t('common.global.noDataFound')}
              </span>
            </div>
          )}
        </Menu>
        {addAllowed && (
          <Button
            type='button'
            variant='actionLarge'
            color='primary'
            className='!ml-2'
            onClick={() => setOpenAddModal(true)}
          >
            <i className='bi bi-plus' />
          </Button>
        )}

        {openAddModal && addAllowed && (
          <CRMBlockAddModal
            open={openAddModal}
            setOpen={setOpenAddModal}
            refetchFn={refetch}
            lastOrder={data ? data.length : 0}
          />
        )}
      </div>
    )
  );
};
export default memo(CRMBlocksMenu);
