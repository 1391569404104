import React, { memo, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { fadeUp } from 'utils/motion';
import useNotification from 'hooks/useNotification';
import FormTextField from 'components/ui/form/FormTextField';
import FormPasswordField from 'components/ui/form/FormPasswordField';
import eskizLogo from 'assets/images/logo/eskiz-logo.svg';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

const validationSchema = yup.object({
  login: yup.string().required('login.validation.loginRequired'),
  password: yup.string().required('login.validation.passwordRequired'),
});

const System = () => {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const sendNotification = useNotification();

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await axiosPrivate.post('/admin/sms/settings', values);
        if (response && response.data && response.data.status) {
          sendNotification({
            msg: t('settings.sms.alerts.systemSuccess'),
            variant: 'success',
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
      }
    },
  });

  return (
    <div className='login-wrapper w-96 px-5 p-4 my-shadow-1 rounded-lg'>
      <div className='flex justify-center my-2'>
        <img src={eskizLogo} alt='eskiz logo' className='h-20' />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <FormTextField
          delay={0.1}
          label={t('common.fields.login')}
          fieldName='login'
          formik={formik}
        />

        <FormPasswordField
          delay={0.2}
          label={t('common.fields.password')}
          fieldName='password'
          formik={formik}
        />

        <div className='text-center mt-3'>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            type='submit'
            component={motion.button}
            variants={fadeUp(30, 'tween', 0.6, 0.5)}
            initial='hidden'
            animate='show'
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={15} color='inherit' className='mr-1' />
            )}
            {t('login.button')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default memo(System);
