import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/ui/filters/items/DateRangeFilter';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

const TaskTypeFilter = ({ disabled = false }) => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [repairTypeValue, setRepairTypeValue] = useState('Barcha topshiriqlar');
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasError, setHasError] = useState(false);

  const { data } = useQuery({
    queryKey: 'tasksList',
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/task/types');
      return response?.data?.data;
    },
    enabled: !hasError,
    onError: () => {
      setHasError(true);
    },
    retry: false,
  });

  const handleRepairTypeChange = (value) => {
    if (value != 'all') {
      searchParams.set('type', value);
      const { name } = data?.filter(({ id }) => id == value)?.[0];
      setRepairTypeValue(name);
    } else {
      searchParams.delete('type');
      setRepairTypeValue('Barcha topshiriqlar');
    }
    setSearchParams(searchParams);
  };

  return (
    <FilterAccordion title={repairTypeValue} code='home-repair-type'>
      <FormControl fullWidth color='formColor' type='filterRadioGroup'>
        <RadioGroup
          aria-labelledby='repair-type-radio-buttons-group'
          name='repair-type-radio-buttons-group-name'
          value={searchParams.get('type') || 'all'}
          onChange={(event) => handleRepairTypeChange(event.target.value)}
        >
          <div className='mb-1 w-full'>
            <FormControlLabel
              className='w-full'
              value={'all'}
              control={<Radio />}
              label={'Barcha topshiriqlar'}
              type='radio'
              disabled={disabled}
            />
          </div>
          {data &&
            data?.length &&
            data?.map((option) => (
              <div className='mb-1 w-full' key={option.id}>
                <FormControlLabel
                  className='w-full'
                  value={option.id}
                  control={<Radio />}
                  label={t(option.name)}
                  type='radio'
                  disabled={disabled}
                />
              </div>
            ))}
          {repairTypeValue == 'custom' ? <DateRangeFilter /> : null}
        </RadioGroup>
      </FormControl>
    </FilterAccordion>
  );
};
export default TaskTypeFilter;
