import { Button } from "@mui/material"
import CrmFilterDrawer from "components/dashboard/CrmFilterDrawer"
import StaffFilter from "components/ui/filters/items/StaffFilter"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import CRMBlocksMenu from "./blocks/CRMBlocksMenu"
import CRMActionsDropDown from "./CRMActionsDropDown"
import CRMLeadAdd from "./lead-actions/CRMLeadAdd"
import CRMLeadSearch from "./lead-actions/CRMLeadSearch"

const CRMTopPanel = ({ addBlock = false, addLead = false }) => {
	const { t } = useTranslation()
	const [openFilter, setOpenFilter] = useState(false)

	return (
		<div className="crm-top-panel-wrapper">
			<CRMBlocksMenu addAllowed={addBlock} />
			<div className="ml-2">
				<Button
					variant="contained"
					onClick={() => setOpenFilter(!openFilter)}
					color="info"
					className="h-10 !min-w-[40px]"
				>
					<span className="text-base font-normal">
						{t("common.button.filter")}
					</span>
					<i className="bi bi-filter text-xl ml-1" />
				</Button>
			</div>
			<div className="flex ml-auto items-center">
				<div className="mr-3 lg:w-[200px] w-[150px]">
					<StaffFilter />
				</div>
				<div className="mr-3">
					<CRMLeadSearch />
				</div>
				<div className="mr-3">
					<CRMLeadAdd />
				</div>
				<div>
					<CRMActionsDropDown />
				</div>

				<CrmFilterDrawer open={openFilter} setOpen={setOpenFilter} />
			</div>
		</div>
	)
}
export default CRMTopPanel
