function createQueryStringFromData(data) {
	if(Object.keys(data).length) {
    return Object.keys(data).reduce((acc, curr, index) => {
      if(data[curr] !== null && data[curr] !== undefined) {
        return  `${acc}${acc.length > 1 ? '&' : ''}${curr}=${data[curr]}`
      }
      return acc
    }, '?')
  }
  return ''
}

export default createQueryStringFromData
