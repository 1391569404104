/* eslint-disable eqeqeq */
import React, { memo, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useQuery } from 'react-query';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import Card from './card';

function Sending() {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: '/admin/sms/templates',
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/sms/templates');
      return response?.data?.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  return (
    <div>
      {isLoading || isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
        >
          {data?.map(({ id, description, name, status }) => (
            <Card
              id={id}
              key={id}
              label={name}
              description={description}
              checked={status == '1'}
            />
          ))}
        </Grid>
      )}
    </div>
  );
}

export default memo(Sending);
