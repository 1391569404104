/** @format */

import { useNavigate } from 'react-router-dom';
import { replaceParamsMultipleQueries } from './replaceParamsMultipleQueries';
const useMultipleQueries = () => {
  const navigate = useNavigate();
  const setMultipleQueries = (queries) => {
    navigate(
      `${window.location.pathname}${replaceParamsMultipleQueries(queries)}`
    );
  };

  return setMultipleQueries;
};

export default useMultipleQueries;
