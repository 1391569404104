import { CircularProgress, Grid } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import usePermission from 'hooks/usePermission';
import useSearch from 'hooks/useSearch';
import { Fragment, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useQuery } from 'react-query';
import CRMOperatorsModal from '../top-panel/operator/CRMOperatorsModal';
import CRMBlockColumnAddModal from './CRMBlockColumnAddModal';
import CRMBlockDroppableColumn from './CRMBlockDroppableColumn';
import createQueryStringFromData from 'utils/createQueryStringFromData';

const CRMBlockColumns = () => {
  const query = useSearch();
  const axiosPrivate = useAxiosPrivate();
  const { hasPermission } = usePermission();
  const [hasError, setHasError] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openOperatorsModal, setOpenOperatorsModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [leadsData, setLeadsData] = useState({});
  const sendNotification = useNotification();

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: ['/crm/block-item/', query.get('crm_block'), query.get('crm_block_name'), query.get('pipeStatusId')],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/crm/block-item/${query.get('crm_block')}${createQueryStringFromData({
          pipeStatusId: query.get('pipeStatusId'),
          crm_block_name: query.get('crm_block_name'),
          user_id: query.get('user_id')
        })}`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      setColumns(
        data && data.length > 0
          ? data.sort((a, b) => parseInt(a.order) - parseInt(b.order))
          : []
      );
    },
    enabled: !hasError && !!query.get('crm_block'),
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (
      result.type == 'COLUMN' &&
      result.source?.index != result.destination?.index
    ) {
      let ordered = JSON.parse(JSON.stringify(columns));
      if (result.source?.index > result.destination?.index) {
        let a = [
          {
            ...columns[result.source?.index],
            order: columns[result.destination?.index]?.order,
          },
        ];
        for (let i = result.destination?.index; i < result.source?.index; i++) {
          a.push({
            ...columns[i],
            order: columns[i + 1]?.order,
          });
        }
        let newCols = [
          ...ordered.slice(0, result.destination?.index),
          ...a,
          ...ordered.slice(result.source?.index + 1),
        ];
        handleChangeColumnOrder(newCols);
      } else {
        let a = [
          {
            ...columns[result.source?.index],
            order: columns[result.destination?.index]?.order,
          },
        ];
        for (let i = result.destination?.index; i > result.source?.index; i--) {
          a.push({
            ...columns[i],
            order: columns[i - 1]?.order,
          });
        }
        let newCols = [
          ...ordered.slice(0, result.source?.index),
          ...a.reverse(),
          ...ordered.slice(result.destination?.index + 1),
        ];
        handleChangeColumnOrder(newCols);
      }
      return;
    }
    if (result.type == 'CARD') {
      if (
        result.source?.droppableId &&
        result.destination?.droppableId &&
        result.source?.droppableId != result.destination?.droppableId
      ) {
        let newLeadsData = JSON.parse(JSON.stringify(leadsData));
        let movingCardItem =
          newLeadsData[parseInt(result.source?.droppableId)][
            result.source?.index
          ];
        if (movingCardItem) {
          movingCardItem.crm_block_item_id = result.destination?.droppableId;
          newLeadsData[parseInt(result.destination?.droppableId)].unshift(
            movingCardItem
          );
          newLeadsData[parseInt(result.source?.droppableId)].splice(
            result.source?.index,
            1
          );
          handleChangeCardColumn(
            movingCardItem,
            result.destination?.droppableId
          );
        }
        setLeadsData(newLeadsData);
      }
    }
  };

  const handleChangeColumnOrder = async (columns) => {
    setColumns(columns);
    try {
      await axiosPrivate.post(
        '/crm/block-item/update',
        JSON.stringify({
          data: columns,
        }),
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };

  const handleChangeCardColumn = async (leadData, columnId) => {
    try {
      await axiosPrivate.post(
        `/crm/crm-lead/jump/${leadData?.id}`,
        JSON.stringify({
          item_id: columnId,
        }),
        { headers: { 'Content-Type': 'application/json' } }
      );
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };

  return (
    <div className='crm-block-columns-wrapper'>
      {isLoading || isFetching ? (
        <div className='circular-progress-box min-h-[500px] h-full w-full'>
          <CircularProgress size={40} />
        </div>
      ) : (
        !!query.get('crm_block') && (
          <Fragment>
            <Grid container spacing={3} className='crm-block-columns-grid'>
              <DragDropContext onDragEnd={onDragEnd}>
                <CRMBlockDroppableColumn
                  data={columns}
                  setOpenAddModal={setOpenAddModal}
                  setOpenOperatorsModal={setOpenOperatorsModal}
                  leadsData={leadsData}
                  setLeadsData={setLeadsData}
                />
              </DragDropContext>
            </Grid>
          </Fragment>
        )
      )}

      {openAddModal && hasPermission('CRM_ADD_BLOCK_ITEM') && (
        <CRMBlockColumnAddModal
          open={openAddModal}
          setOpen={setOpenAddModal}
          refetchFn={refetch}
          blockId={query.get('crm_block')}
          lastOrder={columns ? columns.length : 0}
        />
      )}

      {openOperatorsModal && hasPermission('CRM_OPERATOR') && (
        <CRMOperatorsModal
          open={openOperatorsModal}
          setOpen={setOpenOperatorsModal}
        />
      )}
    </div>
  );
};
export default CRMBlockColumns;
