import { Button, Drawer } from '@mui/material';
import DateTypeFilter from './items/dateTypeFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import CrmColumnsFilter from './items/crmColumnsFilter';
import StaffFilter from '../ui/filters/items/StaffFilter';
import useSearch from 'hooks/useSearch';
import CalltimeRangeFilter from './items/CalltimeRangeFilter';
import TalktimeRangeFilter from './items/TalktimeRangeFilter';
import HideRepeatedCallFilter from './items/hideRepeatedCallFilter';
const leadTypeOptions = [
  {
    code: 'DELETED',
    label: `O'chirilgan lidlar`,
  },
  {
    code: 'ALL',
    label: 'Barcha lidlar',
  },
];

const BossCrmCallsFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useSearch();
  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
      <div className='dashboard-filter-drawer-wrapper'>
        <div className='mb-3 text-end'>
          <Button
            color='error'
            variant='outlined'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            <i className='bi bi-arrow-repeat text-lg mr-1' />
            Tozalash
          </Button>
        </div>
        <DateTypeFilter disabled={isLoading} />
        <CrmColumnsFilter disabled={isLoading} />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='user_id'
        />
        <CalltimeRangeFilter block={query.get('crm_block')}  disabled={isLoading} />
        <TalktimeRangeFilter block={query.get('crm_block')}  disabled={isLoading} />
        <HideRepeatedCallFilter disabled={isLoading} />

      </div>
    </Drawer>
  );
};
export default BossCrmCallsFilterDrawer;
