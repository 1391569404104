import React, { memo, useState } from 'react';
import { useQuery } from 'react-query';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { CircularProgress, Divider } from '@mui/material';

export default memo(function StatisticsSection({ createdAt }) {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { id } = useParams();

  const eventDate = moment(createdAt);
  const todaysDate = moment();
  const days = eventDate.diff(todaysDate, 'days');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['/crm/crm-lead/statistics/id', id],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/crm-lead/statistics/${id}`);
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (res) => {
      //   console.log(res);
      // if (!id) {
      //   const fields = res?.map(({ id }) => ({ field_id: id, value: '' }));
      //   formik.setFieldValue('fields', fields);
      // }
    },
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  return (
    <div className='add-edit-body-wrapper'>
      {isLoading || isFetching ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        <div>
          <div className='lead-statistics text-gray-500 text-sm'>
            <div className='lead-statistics-item flex  items-center  mb-5 justify-between'>
              <div className='lead-statistics-item-title'>Interface</div>
              <div className='lead-statistics-item-value text-gray-700'>
                {eventDate.format('DD.MM.YYYY')}
              </div>
            </div>
            <div className='lead-statistics-item mb-3'>
              <div className='lead-statistics-item-value text-5xl text-base-color-light'>
                {Math.abs(days)}
              </div>
              <div className='lead-statistics-item-title text-base text-black'>
                Faol kunlar
              </div>
            </div>
            <div className='lead-statistics-item flex  items-center  mb-5 justify-between'>
              <div className='lead-statistics-item-title'>Xabarlar</div>
              <div className='lead-statistics-item-value'>
                {data?.chats || 0}
              </div>
            </div>
            <div className='lead-statistics-item flex  items-center  mb-5 justify-between'>
              <div className='lead-statistics-item-title'>
                Qo'ng'iroq kiruvchi/chiquvchi
              </div>
              <div className='lead-statistics-item-value text-gray-700 flex items-center gap-1'>
                <span>
                  <i className='bi bi-telephone-inbound mr-[4px] text-cyan-600' />
                  {data?.income || 0}
                </span>
                <Divider orientation='vertical' sx={{ height: 15 }} />
                <span>
                  <i className='bi bi-telephone-outbound mr-[4px] text-blue-600' />
                  {data?.outcome || 0}
                </span>
              </div>
            </div>
            <div className='lead-statistics-item flex  items-center  mb-5 justify-between'>
              <div className='lead-statistics-item-title'>
                Topshiriqlar bajarilgan/muddati o'tgan
              </div>
              <div className='lead-statistics-item-value text-gray-700 flex items-center gap-1'>
                <span>
                  <i className='bi bi-check-circle mr-[4px] text-cyan-600' />
                  {data?.taskdone || 0}{' '}
                </span>
                <Divider orientation='vertical' sx={{ height: 15 }} />
                <span>
                  <i className='bi bi-x-circle mr-[4px] text-red-600' />
                  {data?.taskactive || 0}
                </span>
              </div>
            </div>
            <div className='lead-statistics-item flex  items-center  mb-5 justify-between'>
              <div className='lead-statistics-item-title'>Eslatmalar</div>
              <div className='lead-statistics-item-value text-gray-700'>
                {0}
              </div>
            </div>
            <div className='lead-statistics-item flex  items-center  mb-5 justify-between'>
              <div className='lead-statistics-item-title'>Suhbatlar</div>
              <div className='lead-statistics-item-value text-gray-700'>
                {0}
              </div>
            </div>
            <div className='lead-statistics-item flex  items-center  mb-5 justify-between'>
              <div className='lead-statistics-item-title'>
                O'rtacha qo'ng'iroq ballari
              </div>
              <div className='lead-statistics-item-value text-gray-700'>
                {0}
                {new Array(5).fill(0).map((a, i) => (
                  <i
                    key={i}
                    className={`bi bi-star-fill mx-[3px] text-gray-300 ${
                      i == 4 && 'mr-0'
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
