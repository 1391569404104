import React from 'react';
import moment from 'moment';
export const ONE_DAY_UNIX_VALUE = 86340;
export const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DateItem = ({ data }) => {
  const calculateDate = () => {
    // const allDay =
    //   moment(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() -
    //     moment(data.deadlineStart, DATE_WITH_TIME_FORMAT).unix() ===
    //   ONE_DAY_UNIX_VALUE;
    // if (allDay) return '';
    // return data.deadlineStart + '-' + data.deadlineFinish;
    return moment(data?.date, DATE_WITH_TIME_FORMAT).format('HH:mm');
  };

  if (
    moment(data.date, DATE_WITH_TIME_FORMAT).unix() <
      moment(new Date()).unix() &&
    !data.closedDate
  )
    return (
      <div className='bg-pink-800 border-orange-700'>
        <div>{data?.crm_lead.name || data?.contractNumber}</div>
        <div>
          {calculateDate()} {data.title}
          {data.text && ': '}
          {data.text}
        </div>
      </div>
    );

  return (
    <div>
      <div>{data?.crm_lead.name || data?.contractNumber}</div>
      <div>
        {calculateDate()}{' '}
        <span>
          {/* shu yerda backend tyne nomini erishi kerak */}
          {data.type_id}
          {data.title && ': '}
        </span>
        {data.title}
      </div>
    </div>
  );
};

export default DateItem;
