import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { motion } from 'framer-motion';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';
import { fadeUp } from 'utils/motion';

const FormSelectField = ({
  delay = 0,
  duration = 0.5,
  formik,
  fieldName,
  path,
  label,
  optionLabelName = 'name',
  optionValueName = 'id',
  readOnly = false,
  disabled = false,
  pathChangeable = false,
  withNestedField = false,
  isItemSizeSmall = false,
  variant = 'outlined',
  emitRefetch = {},
}) => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: fieldName + 'Select',
    queryFn: async function () {
      const response = await axiosPrivate.get(path);
      return response.data.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    if (pathChangeable) {
      refetch();

      if (
        data?.filter(
          (item) => item[optionValueName] == formik.values?.[fieldName]
        ).length == 0
      )
        formik.setFieldValue(fieldName, '', true);
    }
  }, [path]);

  useEffect(() => {
    if (emitRefetch && emitRefetch.refetch) {
      refetch();
      emitRefetch.setRefetch(false);
    }
  }, [emitRefetch.refetch]);

  const getFieldValue = () => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik.values;
      for (let i = 0; i < a.length; i++) {
        b = b[a[i]];
      }
      return b;
    }
    return formik.values[fieldName] || '';
  };

  const getFieldValidation = (formikF) => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik[formikF];
      for (let i = 0; i < a.length; i++) {
        if (b[a[i]]) {
          b = b[a[i]];
        } else {
          b = false;
        }
      }
      return b;
    }
    return formik[formikF][fieldName] || '';
  };

  return (
    <FormControl
      fullWidth
      component={motion.div}
      variants={fadeUp(30, 'tween', delay, duration)}
      initial='hidden'
      animate='show'
      viewport={{ once: true, amount: 0.25 }}
      color='formColor'
      error={
        withNestedField
          ? getFieldValidation('touched') &&
            Boolean(getFieldValidation('errors'))
          : formik.touched[fieldName] && Boolean(formik.errors[fieldName])
      }
    >
      <InputLabel id={`${fieldName}-label`}>{label}</InputLabel>
      <Select
        labelId={`${fieldName}-label`}
        id={`${fieldName}-select`}
        label={label}
        onChange={(event) => {
          formik.setFieldValue(fieldName, event.target.value, true);
        }}
        value={
          isLoading || isFetching
            ? ''
            : withNestedField
            ? getFieldValue()
            : formik.values[fieldName]
        }
        color='formColor'
        variant={variant}
        readOnly={readOnly}
        disabled={disabled}
        role='presentation'
        MenuProps={{
          id: `${fieldName}-select-menu`,
          disableScrollLock: true,
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        {isLoading || isFetching ? (
          <div className='circular-progress-box'>
            <CircularProgress size={25} />
          </div>
        ) : data && data.length > 0 ? (
          data.map((item, index) => (
            <MenuItem
              value={item[optionValueName]}
              key={index + 1}
              className={isItemSizeSmall ? 'small-size' : ''}
            >
              {item[optionLabelName]}
            </MenuItem>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Select>
      <FormHelperText
        children={
          <span>
            {withNestedField
              ? getFieldValidation('touched') &&
                formLocalizedHelperText(getFieldValidation('errors'))
              : formik.touched[fieldName] &&
                formLocalizedHelperText(formik.errors[fieldName])}
          </span>
        }
        error={
          withNestedField
            ? getFieldValidation('touched') &&
              Boolean(getFieldValidation('errors'))
            : formik.touched[fieldName] && Boolean(formik.errors[fieldName])
        }
      />
    </FormControl>
  );
};

export default FormSelectField;
