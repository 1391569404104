import { Box, Tab, Tabs } from '@mui/material';
import useTopPanel from 'hooks/useTopPanel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabOne from './tabs/TabOne';
import TabTwo from './tabs/TabTwo';
import TabThree from './tabs/TabThree';

const Payment = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [tabIndex, setTabIndex] = useState(1);

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('boss.payment.title')}</div>
    );
  }, [i18n.language]);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className='component-wrapper boss-payment-wrapper'>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label="To'lovlar" value={1} />
          <Tab label="To'lov muddati" value={2} />
          <Tab label="Oylik to'lov grafigi" value={3} />
        </Tabs>
      </Box>

      {tabIndex == 1 && <TabOne />}

      {tabIndex == 2 && <TabTwo />}

      {tabIndex == 3 && <TabThree />}
    </div>
  );
};
export default Payment;
