import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { fadeUp } from 'utils/motion';

const SimpleRadioGroupField = ({
  delay = 0,
  duration = 0.5,
  label,
  name,
  value,
  options = [],
  changeFn = () => {},
  disabled = false,
  className = '',
  size = 'medium',
}) => {
  const { t } = useTranslation();

  return (
    <FormControl
      className={className ? `!my-0 ${className}` : '!my-0'}
      fullWidth
      component={motion.div}
      variants={fadeUp(30, 'tween', delay, duration)}
      initial='hidden'
      animate='show'
      viewport={{ once: true, amount: 0.25 }}
      color='formColor'
      type='radiogroup'
    >
      <FormLabel id={`${name}-radio-buttons-group`}>{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby={`${name}-radio-buttons-group`}
        name={`${name}-radio-buttons-group-name`}
        value={value}
        onChange={(event) => changeFn(event.target.value)}
      >
        {options &&
          options.length > 0 &&
          options.map((option) => (
            <FormControlLabel
              key={option.code}
              value={option.code}
              control={<Radio size={size} />}
              label={
                typeof option.label == 'object'
                  ? t(...option.label)
                  : t(option.label)
              }
              disabled={disabled}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SimpleRadioGroupField;
