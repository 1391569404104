import { Link } from 'react-router-dom';
import NavBarLinkItem from 'components/navbar-link/NavBarLinkItem';
import { motion } from 'framer-motion';
import { fade } from 'utils/motion';
import { Button, IconButton } from '@mui/material';
import BaseTooltip from '../ui/tooltips/BaseTooltip';
import useAuth from 'hooks/useAuth';
import { Trans, useTranslation } from 'react-i18next';

const BossSidebarPanel = ({ sideBarToggle, setSideBarToggle }) => {
  const [{ user, logout }] = useAuth();
  const { t } = useTranslation();

  return (
    <div className='sidebar-panel-wrapper my-shadow-2 overflow-y-auto h-full'>
      <div className='sidebar-panel-header h-[100px] flex items-center justify-center xs:flex-row mt-3 p-3'>
        <Link to='/boss/dashboard' className='no-underline'>
          <motion.img
            variants={fade({
              direction: 'left',
              positionHiddenX: '-30px',
              duration: 0.5,
            })}
            initial='hidden'
            animate='show'
            src={require('assets/images/logo/logo.png')}
            alt='logo.png'
            className='w-[100px] xs:m-0 mx-auto'
          />
          <div className='boss-title'>BOSS</div>
        </Link>
        <div className='md:hidden close-btn-wrapper'>
          <IconButton
            variant='onlyIcon'
            color='primary'
            onClick={() => setSideBarToggle(false)}
          >
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </div>

      <ul className='sidebar-links-wrapper mt-2 p-3'>
        <NavBarLinkItem
          linkData={{
            path: 'boss/dashboard',
            title: t('sidebar.boss.dashboard'),
          }}
          iconName='bi bi-house'
          delay={0}
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/payment',
            title: t('sidebar.boss.payment'),
          }}
          iconName='bi bi-cash-coin'
          delay={0.1}
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/arrears',
            title: t('sidebar.boss.arrears'),
          }}
          iconName='bi bi-cash-stack'
          delay={0.2}
          customTextComponent={
            <div className='link-title flex items-center'>
              {t('sidebar.boss.arrears')}{' '}
              <div className='py-1 px-1 ml-1 rounded bg-base-color-light text-[10px] leading-3 italic text-white font-medium'>
                Beta
              </div>
            </div>
          }
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/residential-complex',
            title: t('sidebar.boss.residentialComplex'),
          }}
          iconName='bi bi-houses'
          delay={0.3}
          customTextComponent={
            <div className='link-title flex items-center'>
              {t('sidebar.boss.residentialComplex')}{' '}
              <div className='py-1 px-1 ml-1 rounded bg-base-color-light text-[10px] leading-3 italic text-white font-medium'>
                Beta
              </div>
            </div>
          }
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/contract',
            title: t('sidebar.boss.contract'),
          }}
          iconName='bi bi-clipboard-check'
          delay={0.4}
          customTextComponent={
            <div className='link-title flex items-center'>
              {t('sidebar.boss.contract')}{' '}
              <div className='py-1 px-1 ml-1 rounded bg-base-color-light text-[10px] leading-3 italic text-white font-medium'>
                Beta
              </div>
            </div>
          }
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/crm/*',
            title: t('sidebar.boss.crm'),
          }}
          iconName='bi bi-grid-1x2'
          delay={0.5}
          customTextComponent={
            <div className='link-title flex items-center'>
              {t('sidebar.boss.crm')}{' '}
              <div className='py-1 px-1 ml-1 rounded bg-base-color-light text-[10px] leading-3 italic text-white font-medium'>
                Beta
              </div>
            </div>
          }
        />
        <NavBarLinkItem
          linkData={{
            path: 'boss/sale',
            title: t('sidebar.boss.sale'),
          }}
          iconName='bi bi-graph-up-arrow'
          delay={0.6}
          customTextComponent={
            <div className='link-title flex items-center'>
              {t('sidebar.boss.sale')}{' '}
              <div className='py-1 px-1 ml-1 rounded bg-base-color-light text-[10px] leading-3 italic text-white font-medium'>
                Beta
              </div>
            </div>
          }
        />
      </ul>

      <ul className='sidebar-links-wrapper p-3'>
        <NavBarLinkItem
          linkData={{
            path: 'admin/dashboard',
            title: 'Old-City.uz',
          }}
          iconName='bi bi-arrow-up-right-square-fill'
          delay={0.4}
        />

        <NavBarLinkItem
          linkData={{
            path: 'profile',
            title: t('sidebar.profile'),
          }}
          iconName='bi bi-person'
          delay={0}
          customTextComponent={
            <div className='flex flex-col items-start leading-4 ml-2 overflow-hidden'>
              <span className='link-title text-[0.85rem] w-full overflow-hidden overflow-ellipsis whitespace-nowrap'>
                {user?.user?.name}
              </span>
              <span className='text-gray-400 text-[12px] leading-3'>
                {user?.user?.login}
              </span>
            </div>
          }
        />

        <Button
          variant='outlined'
          onClick={logout}
          className='w-full flex items-center'
        >
          <i className='bi bi-box-arrow-left text-base mr-2' />
          <div className='text-[0.85rem] leading-3'>
            {t('sidebar.actions.logout')}
          </div>
        </Button>
      </ul>

      <div className='toggle-btn-wrapper'>
        {sideBarToggle ? (
          <BaseTooltip
            enterDelay={2000}
            leaveTouchDelay={0}
            title={
              <div>
                <Trans i18nKey='sidebar.actions.close'>
                  Yopish uchun <code className='toggle-btn-sign'>[</code> ni
                  bosing
                </Trans>
              </div>
            }
            arrow={true}
            placement='right'
          >
            <IconButton
              variant='toggle'
              onClick={() => setSideBarToggle(false)}
              aria-label='sidebar-toggle-button'
              aria-describedby='sidebar-toggle-button'
            >
              <i className='bi bi-caret-left' />
            </IconButton>
          </BaseTooltip>
        ) : (
          <BaseTooltip
            enterDelay={2000}
            leaveTouchDelay={0}
            title={
              <div>
                <Trans i18nKey='sidebar.actions.open'>
                  Ochish uchun <code className='toggle-btn-sign'>]</code> ni
                  bosing
                </Trans>
              </div>
            }
            arrow={true}
            placement='right'
          >
            <IconButton
              variant='toggle'
              onClick={() => setSideBarToggle(true)}
              aria-label='sidebar-toggle-button'
              aria-describedby='sidebar-toggle-button'
            >
              <i className='bi bi-caret-right' />
            </IconButton>
          </BaseTooltip>
        )}
      </div>
    </div>
  );
};

export default BossSidebarPanel;
