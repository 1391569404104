const colors = {
  blackColor: '#000000',
  whiteColor: '#ffffff',
  baseColor: '#002454',
  baseColorLight: '#045BCE',
  baseColorLightF15: '#00245426',
  baseColorLightHover: '#00245426',
  baseColorDisabled: '#406190',
  baseColorHover: '#002454',
  baseColorActive: '#002454',
  baseColorOutline: '#002454',
  baseColorDeprecatedBg: '#478DEF',
  baseColorDeprecatedBorder: '#002454',
  base1: '#dadee4',
  base2: '#d7c6df',
  base3: '#478DEF',
  base4: '#002454',
  baseColorActiveDeprecatedF30: '#dbc8e64e',
  baseColorActiveDeprecatedD02: '#d5c5de',
  warningColor: '#f8a62a',
  successColor: '#57c820',
  infoColor: '#0288d1',
  errorColor: '#f40606',
  shadowColor: '#478DEF85',
  formColor: '#007bb2',
  footerColor: '#333333',
  grayColor: '#9ca3af',
};

const colorSeparators = {
  blackColor: '0, 0, 0',
  whiteColor: '255, 255, 255',
  baseColor: '0, 36, 84',
  baseColorLight: '4, 91, 206',
  baseColorLightF15: '0, 36, 84, 0.15',
  baseColorLightHover: '0, 36, 84, 0.15',
  baseColorDisabled: '64, 97, 144',
  baseColorHover: '0, 36, 84',
  baseColorActive: '0, 36, 84',
  baseColorOutline: '0, 36, 84',
  baseColorDeprecatedBg: '71, 141, 239',
  baseColorDeprecatedBorder: '0, 36, 84',
  base1: '218, 222, 228',
  base2: '215, 198, 223',
  base3: '71, 141, 239',
  base4: '122, 26, 174',
  baseColorActiveDeprecatedF30: '219, 200, 230, 0.306',
  baseColorActiveDeprecatedD02: '213, 197, 222',
  warningColor: '248, 166, 42',
  successColor: '87, 200, 32',
  infoColor: '2, 136, 209',
  errorColor: '245, 5, 5',
  formColor: '0, 123, 178',
  footerColor: '51, 51, 51',
};

module.exports = { colors, colorSeparators }
