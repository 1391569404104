import { Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const CommentTaskTypesSelect = ({
  selectedTaskType = {},
  setSelectedTaskType = () => {},
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  let anchorEl = document.getElementById('lead-comment-task-type-btn');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: 'taskTypeSelect',
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/task/types');
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setSelectedTaskType(data[0]);
      }
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleChangeType = (item) => {
    setSelectedTaskType(item);
    menuToggle();
  };
  const menuToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className='task-types-wrapper'>
      <Button
        id='lead-comment-task-type-btn'
        className='lead-comment-task-type-btn'
        onClick={menuToggle}
        variant='select-menu'
        size='extraSmall'
      >
        {selectedTaskType?.name}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={menuToggle}
        className='lead-comment-field-menu'
        PaperProps={{
          sx: {
            marginTop: '0.5rem',
          },
        }}
      >
        {isLoading || isFetching ? (
          <div className='circular-progress-box'>
            <CircularProgress size={20} />
          </div>
        ) : data && data.length > 0 ? (
          data.map((item, index) => (
            <MenuItem
              key={`comment-task-type-${index}`}
              onClick={() => handleChangeType(item)}
              className={
                selectedTaskType?.id == item?.id
                  ? 'lead-comment-field-item is-active'
                  : 'lead-comment-field-item'
              }
            >
              {item.name}
            </MenuItem>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box px-4'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Menu>
    </div>
  );
};
export default CommentTaskTypesSelect;
