import React, { memo, useState } from 'react';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormCurrencyField from 'components/ui/form/FormCurrencyField';
import FormPhoneField2 from 'components/ui/form/FormPhoneField2';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import FormCurrencyField2 from 'components/ui/form/FormCurrencyField2';

import FormTextField2 from 'components/ui/form/FormTextField2';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useQuery } from 'react-query';
import ContactInputForm from './ContactInputForm';

export default memo(function InfoSection({
  formik,
  handleAddPhone,
  handleRemoveContact,
  handleAddContact,
  isSubmitting,
}) {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { id } = useParams();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: 'get fields',
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/crm-settings/fields');
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (res) => {
      //   console.log(res);
      if (!id) {
        const fields = res?.map(({ id }) => ({ field_id: id, value: '' }));
        formik.setFieldValue('fields', fields);
      }
    },
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const getFields = useQuery({
    queryKey: ['/crm/crm-lead/getfields/id', id, data],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/crm-lead/getfields/${id}`);
      return response.data.data;
    },
    enabled: !hasError && data?.length > 0 && Boolean(id),
    onSuccess: (res) => {
      const fields = data?.map(({ id }) => {
        const item = res?.find((f) => f.field_id === id);
        return { field_id: id, value: item?.value || '' };
      });
      //   console.log(fields);
      formik.setFieldValue('fields', fields);
    },
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  //   console.log(formik.values.fields);
  return (
    <div className='add-edit-body-wrapper'>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={{ xs: 2, sm: 3, lg: 3 }}
          rowSpacing={1}
          columns={{ xs: 12, sm: 12, lg: 12 }}
        >
          <Grid item={true} sm={12} xs={12}>
            <div className='item-wrapper'>
              <div className='item-label'>Blok</div>
              <div className='item-value'>
                <FormSelectField
                  delay={0}
                  duration={0}
                  fieldName='crm_block_id'
                  label=''
                  formik={formik}
                  path={'/crm/block'}
                  variant='standard'
                  isItemSizeSmall={true}
                />
              </div>
            </div>
          </Grid>

          {formik.values.crm_block_id && (
            <Grid item={true} sm={12} xs={12}>
              <div className='item-wrapper'>
                <div className='item-label'>Voronka</div>
                <div className='item-value'>
                  <FormSelectField
                    delay={0}
                    duration={0}
                    fieldName='crm_block_item_id'
                    label=''
                    formik={formik}
                    path={`/crm/block-item/${formik.values.crm_block_id}`}
                    variant='standard'
                    isItemSizeSmall={true}
                    pathChangeable={true}
                  />
                </div>
              </div>
            </Grid>
          )}

          <Grid item={true} sm={12} xs={12}>
            <div className='item-wrapper'>
              <div className='item-label'>Mas'ul xodim</div>
              <div className='item-value'>
                <FormSelectField
                  delay={0}
                  duration={0}
                  fieldName='user_id'
                  label=''
                  formik={formik}
                  path={'/dictionary/staffes'}
                  variant='standard'
                  isItemSizeSmall={true}
                />
              </div>
            </div>
          </Grid>

          <Grid item={true} sm={12} xs={12}>
            <div className='item-wrapper'>
              <div className='item-label'>Budjet</div>
              <div className='item-value'>
                <FormCurrencyField
                  delay={0}
                  duration={0}
                  label=''
                  fieldName='budget'
                  formik={formik}
                  variant='standard'
                />
              </div>
            </div>
          </Grid>

          {(id &&
            (getFields?.isLoading || getFields?.isFetching) &&
            !formik.values.fields) ||
          isLoading ||
          isFetching ? (
            <div className='circular-progress-box my-4'>
              <CircularProgress size={25} />
            </div>
          ) : (
            data?.map((field, i) => (
              <Grid item={true} sm={12} xs={12} key={i}>
                <div className='item-wrapper'>
                  <div className='item-label'>{field?.name}</div>
                  <div className='item-value'>
                    {field?.type === 'SELECT' && (
                      <FormSimpleSelectField
                        delay={0}
                        duration={0}
                        withNestedField
                        fieldName={`fields.${i}.value`}
                        formik={formik}
                        options={field?.options}
                        variant='standard'
                        itemValue='name'
                        isItemSizeSmall={true}
                      />
                    )}
                    {field?.type === 'TEXT' && (
                      <FormTextField2
                        delay={0}
                        duration={0}
                        fieldName={`fields.${i}.value`}
                        formik={formik}
                        options={field?.options}
                        path={'/crm/reject-type'}
                        variant='standard'
                        isItemSizeSmall={true}
                      />
                    )}
                    {field?.type === 'NUMBER' && (
                      <FormCurrencyField2
                        delay={0}
                        duration={0}
                        fieldName={`fields.${i}.value`}
                        formik={formik}
                        variant='standard'
                      />
                    )}
                  </div>
                </div>
              </Grid>
            ))
          )}

          <Grid item={true} sm={12} xs={12}>
            <div className='contact-divider'></div>
          </Grid>

          {formik.values.contacts &&
            formik.values.contacts.length > 0 &&
            formik.values.contacts.map((contact, contactInd) => (
              <Grid item={true} sm={12} xs={12} key={`contact-${contactInd}`}>
                <div className='mt-2 my-shadow-1 -mx-1 p-2 rounded-lg leading-5 relative'>
                  <div className='item-wrapper contact-details'>
                    <i className='bi bi-person-circle contact-person-icon' />
                    <FormTextField2
                      delay={0}
                      duration={0}
                      label=''
                      fieldName={`contacts.${contactInd}.name`}
                      formik={formik}
                      variant='standard'
                    />
                  </div>
                  <div>
                    {contact.phones.map((item, index) => (
                      <div
                        className='item-wrapper contact-phones'
                        key={`contact-${contactInd}-phone-${index}`}
                      >
                        <div className='item-label contact-label'>
                          Telefon raqami
                        </div>
                        <ContactInputForm
                          index={index}
                          formik={formik}
                          contact={contact}
                          handleAddPhone={handleAddPhone}
                          contactInd={contactInd}
                        />
                      </div>
                    ))}
                  </div>
                  <div>
                    <div className='item-wrapper'>
                      <div className='item-label contact-label'>Hudud</div>
                      <div className='item-value contact-value'>
                        <FormSelectField
                          delay={0}
                          duration={0}
                          fieldName={`contacts.${contactInd}.region_id`}
                          label=''
                          formik={formik}
                          path={'/dictionary/regions'}
                          withNestedField={true}
                          variant='standard'
                          isItemSizeSmall={true}
                        />
                      </div>
                    </div>
                  </div>
                  {formik.values.contacts.length > 1 && (
                    <div
                      className='close-btn-wrapper'
                      onClick={() => handleRemoveContact(contactInd)}
                    >
                      <IconButton variant='onlyIcon' color='error' size='small'>
                        <i className='bi bi-x' />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Grid>
            ))}

          <Grid item={true} sm={12} xs={12}>
            <div className='-mx-1 mt-1.5'>
              <Button
                color='secondary'
                variant='outlined'
                fullWidth
                onClick={() => handleAddContact()}
              >
                <i className='bi bi-plus-circle mr-1' /> Kontakt qo'shish
              </Button>
            </div>
          </Grid>

          <Grid item={true} sm={12} xs={12}>
            <FormActionButtons delay={0} isSubmitting={isSubmitting} />
          </Grid>
        </Grid>
      </form>
    </div>
  );
});
