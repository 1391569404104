import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormFileUploadField from 'components/ui/form/FormFileUploadField';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useFormSubmit from 'hooks/useFormSubmit';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup
    .string()
    .min(4, { label: 'block.imageAddEditModal.validation.nameMin', value: 4 })
    .required('block.imageAddEditModal.validation.name'),
  image: yup.mixed().required('block.imageAddEditModal.validation.image3D'),
  image2d: yup.mixed().required('block.imageAddEditModal.validation.image2D'),
  location: yup.mixed(),
});

const BlockAddEditImageModal = (props) => {
  const { open, setOpen, blockData, itemData, refetchFn = () => {} } = props;
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      name: itemData?.name || '',
      image: itemData?.link || null,
      image2d: itemData?.link2d || null,
      location: itemData?.location || null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (itemData && itemData?.id) {
        submit(
          { type: 'postUpdate', contentType: 'formData' },
          values,
          '/admin/plan',
          {
            title: t('block.imageAddEditModal.alerts.success', {
              objectName: blockData?.objects?.name,
              blockName: blockData?.name,
            }),
          },
          itemData?.id,
          false,
          handleFinish
        );
      } else {
        submit(
          { type: 'post', contentType: 'formData' },
          { ...values, block_id: blockData?.id },
          '/admin/plan',
          {
            title: t('block.imageAddEditModal.alerts.success', {
              objectName: blockData?.objects?.name,
              blockName: blockData?.name,
            }),
          },
          null,
          false,
          handleFinish
        );
      }
    },
  });

  const handleFinish = () => {
    refetchFn();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {itemData && itemData?.id ? (
          <span>
            {t('block.imageAddEditModal.editTitle', {
              value: itemData?.name,
            })}
          </span>
        ) : (
          <span>{t('block.imageAddEditModal.addTitle')}</span>
        )}
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className='py-3 max-w-[700px]'>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              <Grid item={true} sm={6} xs={12}>
                <FormTextField
                  delay={0.1}
                  label={t('common.fields.planName')}
                  fieldName='name'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} sm={6} xs={12}>
                <FormFileUploadField
                  delay={0.2}
                  accept='.jpg, .png'
                  fieldName='image2d'
                  formik={formik}
                  label={t('common.fields.plan2D')}
                  btnLabel={t('block.imageAddEditModal.uploadPlan2D')}
                  fileSize={1}
                />
              </Grid>

              <Grid item={true} sm={6} xs={12}>
                <FormFileUploadField
                  delay={0.3}
                  accept='.jpg, .png'
                  fieldName='image'
                  formik={formik}
                  label={t('common.fields.plan3D')}
                  btnLabel={t('block.imageAddEditModal.uploadPlan3D')}
                  fileSize={1}
                />
              </Grid>
              <Grid item={true} sm={6} xs={12}>
                <FormFileUploadField
                  delay={0.3}
                  accept='.jpg, .png'
                  fieldName='location'
                  formik={formik}
                  label={t('common.fields.location')}
                  btnLabel={t('block.imageAddEditModal.uploadLocation')}
                  fileSize={1}
                />
              </Grid>

              <Grid item={true} sm={12} xs={12}>
                <FormActionButtons
                  delay={0.4}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  reset={formik.resetForm}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BlockAddEditImageModal;
