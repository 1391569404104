import { Button, ButtonBase, CircularProgress, Menu } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const MessageActionsMenu = ({ itemId = 1, refetchFn = () => {} }) => {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const sendNotification = useNotification()
	const [open, setOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	let anchorEl = document.getElementById(`message-action-menu-btn-${itemId}`)

	const menuToggle = () => {
		setOpen((prev) => !prev)
	}

	const handleDeleteTask = async () => {
		if (itemId) {
			try {
				setIsLoading(true)
				const response = await axiosPrivate.post(
					`/crm/task/delete/${itemId}`,
					{}
				)
				if (response && response.data && response.data.status) {
					sendNotification({
						msg: "Topshiriq muvaffaqiyatli o'chirildi!",
						variant: "success"
					})
					menuToggle()
					refetchFn()
				}
				setIsLoading(false)
			} catch (error) {
				setIsLoading(false)
			}
		}
	}

	return (
		<div className="message-actions-wrapper">
			<ButtonBase
				className="message-action-menu-btn"
				id={`message-action-menu-btn-${itemId}`}
				onClick={menuToggle}
			>
				<i className="bi bi-three-dots-vertical" />
			</ButtonBase>
			<Menu
				open={open}
				anchorEl={anchorEl}
				disableScrollLock={true}
				onClose={menuToggle}
				className="message-action-menu"
				PaperProps={{
					sx: {
						marginTop: "0.5rem"
					}
				}}
			>
				<div className="px-2">
					<Button
						color="error"
						className="message-action-item"
						variant="messageAction"
						size="extraSmall"
						onClick={() => handleDeleteTask()}
						disabled={isLoading}
					>
						{isLoading ? (
							<CircularProgress size={15} color="inherit" className="mr-1" />
						) : (
							<i className="bi bi-trash3 mr-1 text-sm" />
						)}

						{t("common.button.delete")}
					</Button>
				</div>
			</Menu>
		</div>
	)
}
export default MessageActionsMenu
