import useTopPanel from 'hooks/useTopPanel';
import { useEffect } from 'react';
import CRMBlockColumns from './crm-parts/block-columns/CRMBlockColumns';
import CRMTopPanel from './crm-parts/top-panel/CRMTopPanel';
import usePermission from 'hooks/usePermission';

const CRM = () => {
  const { setComponent } = useTopPanel();
  const { hasPermission } = usePermission();

  useEffect(() => {
    setComponent(
      <CRMTopPanel
        addBlock={hasPermission('CRM_ADD_BLOCK')}
        addLead={hasPermission('')}
      />
    );

    return () => setComponent('');
  }, []);

  return (
    <div className='component-list-wrapper bg-base-color-light-hover rounded-lg'>
      <div className='crm-wrapper'>
        <CRMBlockColumns />
      </div>
    </div>
  );
};
export default CRM;
