import {
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CRMLeadAdd = () => {
  const navigate = useNavigate();

  return (
    <Button
      variant='contained'
      color='success'
      onClick={() => {
        navigate('/admin/crm/lead/add');
      }}
      sx={{ minWidth: 40 }}
      className='h-10 !px-0'
    >
      <i className='bi bi-plus-lg text-lg' />
    </Button>
  );
};
export default CRMLeadAdd;
