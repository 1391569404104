import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import DateItem from './DateItem';

export const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_FORMAT = 'MM.YYYY';

const MonthView = ({ data, date }) => {
  const calendarRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (data && data?.length) {
      const events = [];
      data.forEach((item) => {
        const dateEvent = new Date(
          moment(item.date, DATE_WITH_TIME_FORMAT).format()
        );
        events.push({
          id: String(item.id),
          resourceId: item,
          start: dateEvent,
          end: dateEvent,
        });
      });
      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.gotoDate(
        new Date(moment(date, DATE_FORMAT).format()).toISOString()
      );
      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource([...events]);
    }
    if (data?.length === 0) {
      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.gotoDate(
        new Date(moment(date, DATE_FORMAT).format()).toISOString()
      );
      calendarApi?.removeAllEventSources();
    }
  }, [data, calendarRef, date]);

  // got to one day
  // const goToOneDate = (e) => {
  //   dispatch(
  //     setTypeAndDate({
  //       date: moment(e.date).format(DATE_FORMAT),
  //       type: TaskCalendarViewType.DAY,
  //     })
  //   );
  // };

  // handle open complete modal
  // const handleOpenCompleteModal = (task) => {
  //   if (!task.closedDate)
  //     dispatch(
  //       completeTaskVisible({
  //         task,
  //         visible: true,
  //       })
  //     );
  // };

  return (
    <FullCalendar
      // dayHeaderClassNames={styles.container_header}
      // eventClassNames={styles.container_event}
      ref={calendarRef}
      height={'calc(100vh - 200px)'}
      initialView='dayGridMonth'
      dayHeaderContent={(e) => {
        return (
          <div>
            <span className='capitalize'>{moment(e.date).format('dddd')}</span>
          </div>
        );
      }}
      moreLinkContent={(e) => {
        return (
          <div>
            {e.shortText} {t('tasks.title')}
          </div>
        );
      }}
      firstDay={1}
      // dateClick={goToOneDate}
      dayCellContent={(e) => {
        return (
          <div>
            <div>{e.dayNumberText}</div>
          </div>
        );
      }}
      dayCellClassNames={(e) => {
        if (e.isOther) return 'disabled';
        return 'enabled';
      }}
      dragScroll={true}
      eventContent={(e) => {
        return <DateItem data={e?.event?._def?.extendedProps?.resourceId} />;
      }}
      // eventClick={(e) => {
      //   handleOpenCompleteModal(e?.event?._def?.extendedProps?.resourceId);
      // }}
      dayMaxEvents={2}
      dayMaxEventRows={3}
      eventDisplay='block'
      weekends
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      progressiveEventRendering
      headerToolbar={false}
    />
  );
};

export default MonthView;
