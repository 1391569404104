import { Button } from '@mui/material';
import React, { memo } from 'react';
import { t } from 'i18next';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';

export default memo(function AcceptAction({ id, refetch }) {
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();
  const onSubmit = async () => {
    try {
      let response = await axiosPrivate.post(`/crm/crm-lead/accept/${id}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response && response.data && response.data.status) {
        refetch?.();
        sendNotification({
          msg: response?.data?.message || response?.message,
          variant: 'success',
        });
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };
  return (
    <div className='w-0 absolute transition-all origin-right right-0 top-0 group-hover:w-32 bg-base-color-light-f-15 backdrop-blur-xl rounded-r-sm overflow-hidden h-full'>
      <Button
        fullWidth
        size='small'
        className='!rounded-none whitespace-nowrap !justify-start !pl-2 !h-[36px]'
        startIcon={<i className='bi bi-check !leading-4 text-green-500' />}
        onClick={onSubmit}
      >
        {t('common.button.acceptance')}
      </Button>
      <Button
        fullWidth
        size='small'
        className='!rounded-none whitespace-nowrap !justify-start !pl-2 !h-[36px]'
        startIcon={<i className='bi bi-x !leading-4 text-red-500' />}
      >
        {t('common.button.cancel')}
      </Button>
    </div>
  );
});
